import { Action } from '@ngrx/store';

export enum Type {
  LOAD_CONFIGURATION = '[CONFIGURATION] LOAD_CONFIGURATION',
  LOAD_CONFIGURATION_SUCCESS = '[CONFIGURATION] LOAD_CONFIGURATION_SUCCESS'
}

export class LoadConfiguration implements Action {
  readonly type = Type.LOAD_CONFIGURATION;
}

export class LoadConfigurationSuccess implements Action {

  readonly type = Type.LOAD_CONFIGURATION_SUCCESS;
  constructor(public payload: any) { }
}


export type Actions = LoadConfiguration | LoadConfigurationSuccess;
