import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ConfigurationActions from 'app/state/actions/configuration.actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ConfigurationEffects {
  constructor(private actions$: Actions,
    private httpClient: HttpClient) {
  }

  loadConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType<ConfigurationActions.LoadConfiguration>(ConfigurationActions.Type.LOAD_CONFIGURATION),
    switchMap(_ => this.httpClient.get<JSON>('/appsettings.json?ms=' + Date.now().toString(), { headers: { 'init': 'true' } })
      .pipe(
        map(settings => new ConfigurationActions.LoadConfigurationSuccess(settings))
      )
    )
  ));
}
