import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as NavigationActions from 'app/state/actions/navigation.actions';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class NavigationEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location
  ) {
  }


  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.Type.GO),
    map((action: NavigationActions.Go) => action.payload),
    tap(({ path, query: queryParams, extras }
    ) => {
      // TODO: This needs to be fixed before enabling
      // strictActionImmutability for StoreModule
      this.router.navigate([...path], { queryParams, ...extras });
    })
  ), { dispatch: false });


  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.Type.BACK),
    tap(() => this.location.back())
  ), { dispatch: false });


  navigateForward$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.Type.FORWARD),
    tap(() => this.location.forward())
  ), { dispatch: false });
}
