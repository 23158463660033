import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ParameterService } from 'app/shared';
import { CompanyContext, MenuItem, NavtreeState, ParameterItem } from 'app/shared/models';
import * as CompanyContextActions from 'app/state/actions/company-context.actions';
import * as NavTreeActions from 'app/state/actions/navtree.actions';
import * as ReportActions from 'app/state/actions/report.actions';
import * as UserInfoActions from 'app/state/actions/user-info.actions';
import * as UserStateActions from 'app/state/actions/user-state.actions';
import { EMPTY, Observable } from 'rxjs';
import { combineLatestWith, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../app.state';

const CURRENT_GROUP_PARAMETER_NAME = 'currentGroup';

@Injectable()
export class UserStateEffects {
  constructor(private actions$: Actions,
    private store: Store<AppState>,
    private parameterService: ParameterService) { }

  loadState$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CompanyContextActions.SetCompanyContextSuccess>(CompanyContextActions.Type.SET_COMPANY_CONTEXT_SUCCESS),
      combineLatestWith(this.actions$.pipe(ofType<ReportActions.InitPortal>(ReportActions.Type.INIT_PORTAL))),
      withLatestFrom(this.store.select(s => s.userInfo)))
      .pipe(
        filter(([_, userInfo]) => userInfo.userId !== null),
        switchMap(([[companyContextSuccess, __], _]) => {
          return this.parameterService.loadUserState(companyContextSuccess.payload.id)
            .pipe(
              map(result => new UserStateActions.LoadUserStateSuccess(result))
            );
        })));

  updateReportState$ = createEffect(() => this.actions$.pipe(
    ofType<UserStateActions.UpdateUserStateSuccess | UserStateActions.LoadUserStateSuccess | UserInfoActions.SetUserContextLanguageSuccess>
      (UserStateActions.Type.LOAD_USER_STATE_SUCCESS, UserStateActions.Type.UPDATE_USER_STATE_SUCCESS, UserInfoActions.Type.SET_USER_CONTEXT_LANGUAGE_SUCCESS),
    withLatestFrom(this.store.select(s => s.companyContext.id), this.store.select(s => s.userState.companyParameters)),
    filter(([_, __, params]) => params?.length > 0),
    mergeMap(([_, companyId, companyParameters]) => {
      const groupId = companyParameters.find(p => p.companyId === companyId)
        ?.parameters.find(p => p.name === CURRENT_GROUP_PARAMETER_NAME).value;
      return (groupId && companyId)
        ? [new ReportActions.Invalidate(), new ReportActions.InitReportState({ id: +groupId })]
        : EMPTY;
    }))
  );

  loadStateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UserStateActions.LoadUserStateSuccess>(UserStateActions.Type.LOAD_USER_STATE_SUCCESS),
    ofType<ReportActions.InitPortal>(ReportActions.Type.INIT_PORTAL),
    map(_ => new NavTreeActions.LoadNavtree()))
  );

  updateParameter$ = createEffect(() => this.actions$.pipe(
    ofType<UserStateActions.UpdateParameter>(UserStateActions.Type.UPDATE_PARAMETER),
    withLatestFrom(this.store.select(s => s.companyContext), this.store.select(s => s.navtreeState)),
    switchMap(([action, companyContext, navContext]) => {
      return this.parameterActions([action.parameter], companyContext, navContext);
    })
  ));

  updateMultipleParameters$ = createEffect(() => this.actions$.pipe(
    ofType<UserStateActions.UpdateMultipleParameters>(UserStateActions.Type.UPDATE_MULTIPLE_PARAMETERS),
    withLatestFrom(this.store.select(s => s.companyContext), this.store.select(s => s.navtreeState)),
    switchMap(([action, companyContext, navContext]) => {
      return this.parameterActions(action.parameters, companyContext, navContext);
    }))
  );

  private parameterActions(parameterItems: ParameterItem[], companyContext: CompanyContext, navContext: NavtreeState): Observable<Action> {
    return this.parameterService
      .updateUserStateMultiple(companyContext.id, parameterItems)
      .pipe(
        switchMap(companyParams => {
          const returnActions: Action[] = [];

          if (parameterItems.some(p => !!p.invalidates)) {
            returnActions.push(new ReportActions.Invalidate());
          }

          if (navContext.displayParameters.some(p => p.parameterKey && p.context === 'BackendAndFrontend')) {
            if (parameterItems.some(p => p.name === 'currentGroup')) {
              const items: MenuItem[] = navContext.items.flatMap(tree => tree.items).map(i => ({ key: i.key, visible: i.visible })) as MenuItem[];
              return [
                ...returnActions,
                new NavTreeActions.UpdateParameterMenuVisibility(companyParams, items)
              ];
            } else {
              return [
                ...returnActions,
                new UserStateActions.UpdateUserStateSuccess(companyParams),
                new NavTreeActions.UpdateVisibility(companyParams, null)
              ];
            }

          } else if (navContext.displayParameters.some(p => p.parameterKey && p.context === 'Frontend')) {
            return [
              new UserStateActions.UpdateUserStateSuccess(companyParams),
              new NavTreeActions.UpdateVisibility(companyParams, null)
            ];
          } else {
            return [new UserStateActions.UpdateUserStateSuccess(companyParams)];
          }
        })
      );
  }
}
