import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {act, Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NavtreeItem } from 'app/shared/models';
import { SurveyService, UserService } from 'app/shared/services';
import * as CompanyContextActions from 'app/state/actions/company-context.actions';
import * as NavtreeActions from 'app/state/actions/navtree.actions';
import * as UserInfoActions from 'app/state/actions/user-info.actions';
import {EMPTY, from} from 'rxjs';
import {concatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import { AppState } from '../app.state';
import * as ReportActions from 'app/state/actions/report.actions';

@Injectable()
export class UserInfoEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private userService: UserService,
    private surveyService: SurveyService,
    private translate: TranslocoService) { }

  loadUserInfo$ = createEffect(() => this.actions$.pipe(
    ofType<UserInfoActions.LoadUserInfo>(UserInfoActions.Type.LOAD_USER_INFO),
    switchMap(action => this.userService.userInfo(action.payload)
      .pipe(map(userInfo => new UserInfoActions.LoadUserInfoSuccess(userInfo)))
    )
  ));

  setUserLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<UserInfoActions.SetUserLanguage>(UserInfoActions.Type.SET_USER_LANGUAGE),
    withLatestFrom(this.store.select(s => s.companyContext.companyLanguages)),
    switchMap(([action, languages]) => this.userService.setUserLanguage(action.payload).pipe(
      switchMap(_ => {
        const lang = languages.find(cl => cl.id === action.payload?.id);
        if (!lang) return EMPTY;
        return [
        new UserInfoActions.SetUserLanguageSuccess(action.payload),
        new UserInfoActions.SetUserContextLanguage(action.payload)
      ]})
    )
    )
  ));

  setUserLanguageFromToken$ = createEffect(() => this.actions$.pipe(
    ofType<UserInfoActions.SetUserLanguageFromToken>(UserInfoActions.Type.SET_USER_LANGUAGE_FROM_TOKEN),
    switchMap(action => this.userService.setUserLanguageFromToken(action.payload.token, action.payload.language.id).pipe(
      switchMap(_ => [
        new UserInfoActions.SetUserLanguageSuccess(action.payload.language),
        new UserInfoActions.SetUserContextLanguage(action.payload.language)
      ])
    ))
  ));

  setUserContextLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<UserInfoActions.SetUserContextLanguage>(UserInfoActions.Type.SET_USER_CONTEXT_LANGUAGE),
    withLatestFrom(this.store.select(s => s.companyContext.companyLanguages)),
    switchMap(([action, languages]) => {
      const lang = languages.find(cl => cl.id === action.payload?.id);
      if (!lang) return EMPTY;
      this.translate.setActiveLang(lang.code);
      return [new UserInfoActions.SetUserContextLanguageSuccess(lang)];
    })
  ));

  loadContextFromToken$ = createEffect(() => this.actions$.pipe(
    ofType<UserInfoActions.LoadContextFromToken>(UserInfoActions.Type.LOAD_CONTEXT_FROM_TOKEN),
    switchMap(action => this.surveyService.getContext(action.token).pipe(
      switchMap(context => {
        const userLangId = context.userContext.languageId;
        const defaultLang = context.companyContext.companyLanguages.find(cl => cl.isDefaultCompanyLanguage);
        const lang = context.companyContext.companyLanguages.find(cl => cl.id === userLangId) ?? defaultLang;
        const setUserLanguage = new UserInfoActions.SetUserContextLanguage(lang);
        const loadUserSuccessAction = new UserInfoActions.LoadUserInfoSuccess(context.userContext);
        const setCompanyContextSuccess = new CompanyContextActions.SetCompanyContextSuccess({
          ...context.companyContext
        });

        for (const menuItem of context.menu) {
          menuItem.route = [];
        }
        const items = context.menu.groupBy(x => x.displayType).map((x): NavtreeItem =>
          ({ type: x.key, items: x.values, isAdmin: false }));
        const loadNavtreeSuccessAction = new NavtreeActions.LoadNavtreeSuccess(items);
        return [
          setCompanyContextSuccess,
          loadUserSuccessAction,
          // loadNavtreeSuccessAction,
          setUserLanguage,
          new ReportActions.InitReportState({})
        ];
      })
    )
    )
  ));

  switchUser$ = createEffect(() => this.actions$.pipe(
      ofType<UserInfoActions.SwitchUserContext>(UserInfoActions.Type.SWITCH_USER_CONTEXT),
      switchMap((action) =>
        this.userService.switchUser(action.payload)
          .pipe(
            concatMap(switchUserResponse => [
              new UserInfoActions.LoadUserInfoSuccess(switchUserResponse.userContext),
              new CompanyContextActions.SetCompanyContextSuccess({ ...switchUserResponse.companyContext }),
              ...action.onSuccess
            ])
          )
        )
      )
    );
}

