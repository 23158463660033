import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductService } from 'app/shared';
import { FollowupCreate, FollowupCreateAction, FollowupWizardContext, WizardTypes } from 'app/shared/models';
import * as FollowupWizardActions from 'app/state/actions/followup-wizard.actions';
import * as NavigationActions from 'app/state/actions/navigation.actions';
import * as ProductWizardActions from 'app/state/actions/product-wizard.actions';
import { AppState } from 'app/state/app.state';
import { EMPTY, from } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { initialState as DefaultFollowupWizardState } from '../reducers/followup-wizard.reducer';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { DialogType } from '../../shared/confirmation-dialog/dialog.type';

@Injectable()
export class FollowupWizardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private productService: ProductService,
    private dialogService: ConfirmationDialogService) {
  }
  startFollowup$ = createEffect(() => this.actions$.pipe(
    ofType<FollowupWizardActions.StartFollowup>(FollowupWizardActions.Type.START_FOLLOWUP),
    withLatestFrom(this.store.select(s => s.companyContext)),
    switchMap(([action, context]) => {
      const state = { ...DefaultFollowupWizardState } as FollowupWizardContext;
      state.productDefinition = action.payload;
      state.recommendations = action.recommendations;
      state.groupResultInfo = action.groupResultInfo;
      state.groupId = action.groupId;
      const groupResultId = action.groupResultInfo ? action.groupResultInfo.groupResultId : -1;

      if (!action.productId) {
        return this.productService.create(context.id, action.payload.id, JSON.stringify(state), groupResultId, action.groupId)
          .pipe(
            switchMap(product => from([
              new ProductWizardActions.SetWizardState({ ...state, productId: product.id, currentVersion: product.currentVersion })
            ]))
          );
      }
      return this.productService.getById(action.productId)
        .pipe(
          switchMap(product => {
            if (product === null) {
              return this.productService.create(context.id, action.payload.id, JSON.stringify(state), groupResultId)
                .pipe(
                  switchMap(product => from([
                    new ProductWizardActions.SetWizardState({ ...state, productId: product.id, currentVersion: product.currentVersion })
                  ]))
                );
            } else if (product !== null) {
              let pState = (product.state != null ? JSON.parse(product.state) : state) as FollowupWizardContext;
              // Temporary fix to fix old data, remove code when future arrives
              if (!pState.groupResultInfo && action.groupResultInfo) { pState.groupResultInfo = action.groupResultInfo; }
              // Todo: remove line above
              pState = { ...pState, productId: product.id, actions: product.actions };
              return from([
                new ProductWizardActions.SetWizardState(pState)
              ]);
            }
          })
        );
    })
  ));

  selectFollowupProductDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SelectProductDefinition>(ProductWizardActions.Type.SELECT_PRODUCT_DEFINITION),
    filter(action => action.wizardType === WizardTypes.followup),
    switchMap(action => from([new ProductWizardActions.SetupDraft(action.payload, WizardTypes.followup)]))
  ));

  saveDraftFromFollowUpWizard$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SaveDraft>(ProductWizardActions.Type.SAVE_DRAFT),
    filter(action => action.payload.wizardType === WizardTypes.followup),
    switchMap(action => {
      let payload = action.payload as FollowupWizardContext;
      const draft = JSON.stringify({ ...action.payload, unsaved: false });
      this.store.dispatch(new ProductWizardActions.SetChanged({ changed: false }));
      return this.updateDraft(payload, draft, action.showMessage);
    })
  ));

  saveDraftAndGoBackFollowUpWizard$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SaveDraft>(ProductWizardActions.Type.SAVE_DRAFT_GO_BACK),
    filter(action => action.payload.wizardType === WizardTypes.followup),
    switchMap(action => {
      let payload = action.payload as FollowupWizardContext;
      const draft = JSON.stringify(action.payload);
      return this.updateDraft(payload, draft, action.showMessage)
        .pipe(
          switchMap(_ => from([new NavigationActions.Back()])));
    })
  ));

  startFlow$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SetWizardState>(ProductWizardActions.Type.SET_WIZARD_STATE),
    filter(action => action.payload?.wizardType === WizardTypes.followup),
    withLatestFrom(this.store.select(s => s.companyContext)),
    switchMap(([action, context]) =>
      from([new NavigationActions.Go({ path: ['portal', context.shortName, 'followup', action.payload.productId] })]))
  ));

  createSurvey$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.CreateProduct>(ProductWizardActions.Type.CREATE_PRODUCT),
    filter(action => action.payload.wizardType === WizardTypes.followup),
    map(action => this.mapContextToFollowupCreate(action.payload as FollowupWizardContext)),
    withLatestFrom(this.store.select(s => s.companyContext)),
    switchMap(([model, companyContext]) => this.productService
      .createFollowup(companyContext.id, model)
      .pipe(map(x => new ProductWizardActions.ProductCreated(x.id, WizardTypes.followup))
      ))
  ));

  SetPage$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SetPage>(ProductWizardActions.Type.SET_PAGE),
    filter(action => action.wizardType === WizardTypes.followup),
    withLatestFrom(this.store.select(s => s.followupWizardContext)),
    switchMap(([_, followupWizardContext]) => from([new ProductWizardActions.SaveDraft(followupWizardContext)]))
  ));
  
  saveDraft$ = createEffect(() => this.actions$.pipe(
    ofType(
      FollowupWizardActions.Type.ADD_GOAL,
      FollowupWizardActions.Type.EDIT_GOAL,
      FollowupWizardActions.Type.ADD_NOW,
      FollowupWizardActions.Type.EDIT_NOW,
      FollowupWizardActions.Type.ADD_ACTION,
      FollowupWizardActions.Type.EDIT_ACTION),
    withLatestFrom(this.store.select(s => s.followupWizardContext)),
    switchMap(([_, context]) =>
      from([new ProductWizardActions.SaveDraft(context, false)])
    )));

  deleteProduct$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.DeleteProduct>(ProductWizardActions.Type.DELETE_PRODUCT),
    filter(action => action.wizardType === WizardTypes.followup),
    switchMap((action) => this.productService.delete(action.productId))
  ), { dispatch: false });

  setChanged$ = createEffect(() => this.actions$.pipe(
    ofType(FollowupWizardActions.Type.SELECTED_RECOMMENDATION
    ),
    withLatestFrom(this.store.select(s => s.followupWizardContext)),
    switchMap(([action, context]) => {
      if (!context.unsaved) {
        return from([new ProductWizardActions.SetChanged({ changed: true })]);
      }
      return from(EMPTY)
    })
  ));

  private updateDraft(payload: FollowupWizardContext, draft: string, showMessage: boolean) {
    return this.productService.updateDraft(payload.productId, { draft: draft, draftVersion: payload.currentVersion, checkVersion: true }).pipe(
      map(result => {
        const currentVersion = JSON.parse(result.body).currentVersion;
        return new ProductWizardActions.DraftSaved({ productId: payload.productId, currentVersion: currentVersion }, payload.wizardType, showMessage);
      }),
      catchError(err => {
        if (err?.error === '"Outdated version"') {
          return this.productService.getVersion(payload.productId)
            .pipe(switchMap((response) => {
              return this.dialogService.openDialog<boolean>(
                'INSIGHT.ADMIN.PRODUCTWIZARD.VERSION_WARNING.TITLE_WARNING',
                'INSIGHT.ADMIN.PRODUCTWIZARD.VERSION_WARNING.BODY_WARNING',
                500,
                DialogType.Confirm,
                'INSIGHT.ADMIN.PRODUCTWIZARD.VERSION_WARNING.CONTINUE',
                'INSIGHT.ADMIN.PRODUCTWIZARD.VERSION_WARNING.CANCEL',
                true,
                null,
                true,
                '',
                `${response.lastUpdatedBy ?? 'N/A'} ${response.lastUpdatedDate ? new Date(response.lastUpdatedDate).toLocaleString() : ''}`
              )
                .pipe(
                  switchMap(response => {
                    if (response) {                      
                      return this.productService.updateDraft(payload.productId, { draft: draft, draftVersion: payload.currentVersion, checkVersion: false }).pipe(
                        map(httpResult => {
                          const currentVersion = JSON.parse(httpResult.body).currentVersion;
                          return new ProductWizardActions.DraftSaved({ productId: payload.productId, currentVersion: currentVersion }, payload.wizardType, showMessage)
                        }
                        ));
                    }
                    else {
                      this.store.dispatch(new NavigationActions.Go({ path: ['portal', this.store.select(s => s.companyContext.shortName), 'followup'], extras: { state: { bypassFormGuard: true } } }));
                    }
                  }));
            }))
        }
      }))
  }

  private mapContextToFollowupCreate(context: FollowupWizardContext): FollowupCreate {
    return <FollowupCreate>{
      productId: context.productId,
      groupResultId: context.groupResultInfo.groupResultId,
      groupId: context.groupId,
      actions: context.actions.map(x => <FollowupCreateAction>{
        id: x.id,
        title: x.title,
        description: x.description,
        deadline: x.deadline,
        responsibleUserIds: x.responsibleUsers.map(u => u.id),
        targetGroupIds: x.targetGroupIds,
        recommendationId: context.selectedRecommendation ? context.selectedRecommendation.id : null,
        category: context.selectedCategory ? context.selectedCategory.key : null,
        subCategory: context.selectedSubCategory ? context.selectedSubCategory.subCategoryKey : null
      })
    };
  }
}
