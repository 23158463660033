import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyContext, MenuItem, NavtreeItem } from 'app/shared/models';
import { MenuService } from 'app/shared/services';
import * as CompanyContextActions from 'app/state/actions/company-context.actions';
import * as NavtreeActions from 'app/state/actions/navtree.actions';
import * as UserInfoActions from 'app/state/actions/user-info.actions';
import * as UserStateActions from 'app/state/actions/user-state.actions';
import * as ReportActions from 'app/state/actions/report.actions';
import { combineLatestWith, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../app.state';


@Injectable()
export class NavtreeEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private menuService: MenuService) { }


  updateParameterMenuVisibility$ = createEffect(() => this.actions$.pipe(
    ofType<NavtreeActions.UpdateParameterMenuVisibility>(NavtreeActions.Type.UPDATE_PARAMETER_MENU_VISIBILITY))
    .pipe(
      withLatestFrom(this.store.select(s => s.companyContext)),
      switchMap(([paramAction, companyContext]) => {
        return this.menuService
          .getMenuVisibility(paramAction.params, paramAction.menuItems)
          .pipe(
            switchMap(response => {
              const missingMenuItems = this.setUpMenuItems(null, response.missingMenuItems, companyContext);
              return [
                new NavtreeActions.UpdateMissingVisibility(missingMenuItems),
                new NavtreeActions.UpdateVisibility(paramAction.params, response.menuItems),
                new UserStateActions.UpdateUserStateSuccess(paramAction.params),
              ];
            })
          );

      })
    ));

  loadNavtree$ = createEffect(() => this.actions$.pipe(
    ofType<NavtreeActions.LoadNavtree>(NavtreeActions.Type.LOAD_NAVTREE))
    .pipe(
      combineLatestWith(
        this.actions$.pipe(ofType<CompanyContextActions.SetCompanyContextSuccess>(CompanyContextActions.Type.SET_COMPANY_CONTEXT_SUCCESS)),
        this.actions$.pipe(ofType<UserStateActions.LoadUserStateSuccess>(UserStateActions.Type.LOAD_USER_STATE_SUCCESS))
      ),
      filter(([_, companyContextSuccess, loadUser]) => companyContextSuccess.payload.id === loadUser.payload.currentCompanyId),

      switchMap(([_, companyContextSuccess]) =>
        this.menuService.getMenu()
          .pipe(switchMap(menuItems => {
            const setupMenu = this.setUpMenuItems(null, menuItems, companyContextSuccess.payload);
            const items = setupMenu.groupBy(x => x.displayType).map((x): NavtreeItem =>
              ({ type: x.key, items: x.values, isAdmin: false }));
            return [new NavtreeActions.LoadNavtreeSuccess(items)];
          }))
      )));

  loadNavtreeCancel$ = createEffect(() => this.actions$.pipe(
    ofType<NavtreeActions.LoadNavtree>(NavtreeActions.Type.LOAD_NAVTREE),
    combineLatestWith(this.actions$.pipe(ofType<UserInfoActions.LoadContextFromToken>(UserInfoActions.Type.LOAD_CONTEXT_FROM_TOKEN))))
    .pipe(switchMap(_ => [new NavtreeActions.LoadNavtreeCanceled()])));

  initReportStateOnEmptyMenu$ = createEffect(() => this.actions$.pipe(
    ofType<NavtreeActions.LoadNavtreeSuccess>(NavtreeActions.Type.LOAD_NAVTREE_SUCCESS),
    filter(action => action.payload.length === 0)
  ).pipe(switchMap(_ => [new ReportActions.InitReportState({})])));

  setUpMenuItems(parentMenuItem: MenuItem, menuItems: MenuItem[], companyContext: CompanyContext): MenuItem[] {
    const menuCopy: MenuItem[] = [];
    for (const menuItem of menuItems) {
      menuItem.parentKey = parentMenuItem ? parentMenuItem.key : null;
      menuItem.hasSameTypeChildren = menuItem.children
        ? menuItem.children.filter(c => c.displayType === menuItem.displayType).length > 0 : false;
      if (menuItem.hasChildren) {
        const filteredChildren = this.setUpMenuItems(menuItem, menuItem.children, companyContext);
        menuItem.children = filteredChildren;
        menuItem.hasChildren = filteredChildren.length > 0;

        if (menuItem.hasChildren) {
          menuCopy.push(...filteredChildren);
        }
      }
      menuItem.route = this.buildRoutes(parentMenuItem, menuItem, companyContext);
      menuCopy.push(menuItem);
    }

    return menuCopy;
  }

  private buildRoutes(parentItem: MenuItem, item: MenuItem, companyContext: CompanyContext): string[] {

    if ((item.reportPageItems && item.reportPageItems.length > 0) || (item.key)) {

      if (parentItem) {
        if (parentItem.parentKey) {
          return ['/portal', companyContext.shortName, 'page', parentItem.parentKey, parentItem.key, item.key];
        } else {
          return ['/portal', companyContext.shortName, 'page', parentItem.key, item.key];
        }
      } else {
        if (item.reportPageItems && item.reportPageItems.length > 0) {
          return ['/portal', companyContext.shortName, 'page', item.key];
        } else {
          const r = item.children.filter(i => i.reportPageItems && i.reportPageItems.length > 0);
          if (r.length > 0) {
            return ['/portal', companyContext.shortName, 'page', item.key];
          } else {
            return [''];
          }
        }
      }
    }
    return [''];
  }
}

