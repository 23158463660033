import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {NeedsToUnsubscribe, QuestionService} from 'app/shared';
import { Answer, Condition, Operand, Survey, SurveyPageGroup, SurveyRule } from 'app/shared/models';
import { AuthenticationService, ModuleService, SurveyService } from 'app/shared/services';
import { SurveyNavigationBookmark } from 'app/shared/survey-render/models';
import * as SurveyUIActions from 'app/state/actions/survey-ui.actions';
import { AppState } from 'app/state/app.state';
import { EMPTY, from, of } from 'rxjs';
import { catchError, combineLatestWith, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LoadContextFromToken } from './../actions/user-info.actions';

export interface TargetAction {
  id: number;
  visible: boolean;
}
@Injectable()
export class SurveyUIEffects extends NeedsToUnsubscribe {
  survey: Survey;
  constructor(
    private actions$: Actions,
    private surveyService: SurveyService,
    private moduleService: ModuleService,
    private questionService: QuestionService,
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private translate: TranslocoService) { super(); }

  initSurvey$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.InitSurveyApp>(SurveyUIActions.Type.INIT_SURVEY_APP),
    switchMap(_ => {
      return [new SurveyUIActions.SetupSurveyContext()];
    })
  ));

  setupContext$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.SetupSurveyContext>(SurveyUIActions.Type.SETUP_SURVEY_CONTEXT),
    combineLatestWith(this.store.select(x => x.router)),
    map(([_, router]) => this.getRouteParams(router.state.root)),
    switchMap(params => from([new LoadContextFromToken(String(params['token']))]))
  ));


  loadSurveyContent$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.SetupSurveyContext>(SurveyUIActions.Type.SETUP_SURVEY_CONTEXT),
    combineLatestWith(this.store.select(s => s.userInfo.displayLanguageId)),
    withLatestFrom(this.store.select(x => x.router)),
    filter(([[_, displayLangId], __]) => !!displayLangId),
    switchMap(([[_, displayLangId], router]) => {
      const params = this.getRouteParams(router.state.root);
      return this.surveyService.get(String(params['token']), displayLangId)
    }),
    switchMap(survey => from([new SurveyUIActions.LoadSurveyContentSuccess(survey)])),
    catchError(error => {
      if (error?.error?.error === 'session expired') {
        return of(new SurveyUIActions.LoadSurveySurveyExpired());
      } else {
        this.authService.startSigninMainWindow('');
      }
    })
  ));


  loadSurveyStateFromDatabase$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.LoadSurveyContentSuccess>(SurveyUIActions.Type.LOAD_SURVEY_CONTENT_SUCCESS),
    withLatestFrom(this.store.select(x => x.router), this.store.select(x => x.surveyState.pageGroups)),
    map(([_, router, pageGroups]) => [
      this.getRouteParams(router.state.root)['token'],
      this.getQuestionIds(pageGroups)
    ]),
    switchMap(([token, questionIds]) => this.surveyService.getState(token).pipe(
      switchMap(data => {
        let serverSurveyAnswers: Answer[] = [];
        serverSurveyAnswers = JSON.parse(data) as Answer[];

        let validAnswers: Answer[] = [];
        if (serverSurveyAnswers && serverSurveyAnswers.length) {
          validAnswers = serverSurveyAnswers.filter(a => questionIds.includes(a.questionId));
        }
        return of(new SurveyUIActions.LoadSurveyAnswersFromDatabase(validAnswers));
      }))
    )
  ));

  loadSurveyAnswersFromDatabaseSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.LoadSurveyAnswersFromDatabase>(SurveyUIActions.Type.LOAD_ANSWERS_DATABASE),
    filter(action => action.payload != null),
    map(action => {
      return new SurveyUIActions.LoadSurveyAnswersFromDatabaseSuccess(action.payload);
    })
  ));

  goToNextPage = createEffect(() => this.actions$.pipe(
    ofType(SurveyUIActions.Type.NEXT_PAGE, SurveyUIActions.Type.START_SURVEY),
    withLatestFrom(this.store.select(s => s.surveyState.pageCurrent)),
    switchMap(([_, page]) => {

      return [new SurveyUIActions
        .GoToPage({ chapterIndex: null, pageIndex: page + 1 })
      ];
    })
  ));

  goToPreviousPage = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.PreviousPage>(SurveyUIActions.Type.PREVIOUS_PAGE),
    withLatestFrom(this.store.select(s => s.surveyState.pageCurrent)),
    switchMap(([_, page]) =>
      [new SurveyUIActions
        .GoToPage({ chapterIndex: null, pageIndex: page - 1 })])
  ));

  fastForwardPage = createEffect(() => this.actions$.pipe(
    ofType(SurveyUIActions.Type.FAST_FORWARD_PAGE),
    withLatestFrom(this.store.select(s => s.surveyState.fastForwardChapter),
      this.store.select(s => s.surveyState.fastForwardPage)),
    switchMap(([_, ffChapter, ffPage]) =>
      [new SurveyUIActions
        .GoToPage({ chapterIndex: ffChapter, pageIndex: ffPage })])
  ));

  goToPageCheck = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.GoToPage>(SurveyUIActions.Type.GO_TO_PAGE),
    filter(action => action.payload != null),
    withLatestFrom(
      this.store.select(s => s.surveyState.fastForwardChapter),
      this.store.select(s => s.surveyState.fastForwardPage),
      this.store.select(s => s.surveyState.chapterCurrent)
    ),
    switchMap(([action, fastForwardChapter, fastForwardPage, chapterCurrent]) => {

      const bookmark = { ...action.payload };

      if (!bookmark.chapterIndex) {
        bookmark.chapterIndex = chapterCurrent;
      }

      if (bookmark.chapterIndex > fastForwardChapter) {
        return [new SurveyUIActions.GoToPageFailure()];
      }

      return [new SurveyUIActions
        .GoToPageSuccess(<SurveyNavigationBookmark>{ chapterIndex: bookmark.chapterIndex, pageIndex: bookmark.pageIndex })
      ];
    })));

  removeSurveyState$ = createEffect(() => this.actions$.pipe(
    ofType(SurveyUIActions.Type.SUBMIT_SURVEY_SUCCESS, SurveyUIActions.Type.CLEAR_STATE),
    withLatestFrom(this.store.select(s => s.router)),
    map(([action, router]) => [action, this.getRouteParams(router.state.root)]),
    tap(([_, params]) => this.surveyService.updateState(params['token'], '')),
    switchMap(() => from([]))
  ));

  loadPreview$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.LoadPreview>(SurveyUIActions.Type.LOAD_PREVIEW),
    switchMap(action => this.moduleService.preview(action.payload)
      .pipe(map(previewResult => {
        return new SurveyUIActions.LoadPreviewSuccess(previewResult);
      })))
  ));

  loadQuestionPreview$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.LoadQuestionPreview>(SurveyUIActions.Type.LOAD_QUESTION_PREVIEW),
    switchMap(action => 
      this.moduleService.preview(action.payload)
      .pipe(map(previewResult => {
        if (action.payload.fieldOverride) {
          previewResult.pageGroups[0].pages[0].pageItems[0].question.questionFields = action.payload.fieldOverride;
        }
        return new SurveyUIActions.LoadPreviewSuccess(previewResult);
      })))
  ));

  loadQuestionFieldPreview$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.LoadQuestionFieldPreview>(SurveyUIActions.Type.LOAD_QUESTION_FIELD_PREVIEW),
    switchMap(action => this.questionService.previewQuestionField(action.payload)
      .pipe(map(previewResult => {
        return new SurveyUIActions.LoadPreviewSuccess(previewResult);
      })))
  ));

  saveDBState$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.RegisterAnswer
      | SurveyUIActions.RemoveAnswer>(SurveyUIActions.Type.REGISTER_ANSWER, SurveyUIActions.Type.REMOVE_ANSWER),
    withLatestFrom(
      this.store.select(s => s.surveyState.answers),
      this.store.select(x => x.router),
      this.store.select(s => s.surveyState.previewMode)),
    map(([_, answers, router, previewMode]) => {
      const surveyKey = this.getRouteParams(router.state.root);
      if (surveyKey && !previewMode) {
        return new SurveyUIActions.SaveAnswersDatabase(answers);
      } else {
        return new SurveyUIActions.SaveAnswersDatabaseFailure('preview')
      }
    })));

  saveDBStateSuccess = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.SaveAnswersDatabase>(SurveyUIActions.Type.SAVE_ANSWERS_DATABASE),
    withLatestFrom(this.store.select(x => x.router)),
    map(([action, router]) => {
      const params = this.getRouteParams(router.state.root);
      const surveyKey = params['token'];
      return [surveyKey, action.payload];
    }),
    switchMap(([key, payload]) => this.surveyService.updateState(key, JSON.stringify(payload)).pipe(
      catchError((x: HttpErrorResponse) => from([x]))
    )),
    map(response => response.ok ? new SurveyUIActions.SaveAnswersDatabaseSuccess()
      : new SurveyUIActions.SaveAnswersDatabaseFailure(response.status))
  ));

  evaluateRules$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.RegisterAnswer
      | SurveyUIActions.RemoveAnswer
      | SurveyUIActions.StartSurvey
      | SurveyUIActions.LoadSurveyAnswersFromDatabase>
      (SurveyUIActions.Type.REGISTER_ANSWER, SurveyUIActions.Type.REMOVE_ANSWER,
        SurveyUIActions.Type.START_SURVEY, SurveyUIActions.Type.LOAD_ANSWERS_DATABASE_SUCCESS),
    withLatestFrom(this.store.select(s => s.surveyState)),
    filter(([_, state]) => state.rules.length > 0),
    switchMap(([action, state]) => {
      const changes = [];
      const answers = [...state.answers];
      if (action instanceof SurveyUIActions.RegisterAnswer || action instanceof SurveyUIActions.RemoveAnswer) {
        const source = action.payload;
        const filteredDependeciesCount = state.ruleDependencies
          .filter(dep => dep.sourceId === source.questionId).length;
        if (filteredDependeciesCount === 0) { return from(EMPTY); }
      }
      this.evaluateRules(state.rules, answers, changes);
      return from(changes);
    })
  ));

  updateFastForward = createEffect(() => this.actions$.pipe(
    ofType(SurveyUIActions.Type.REGISTER_ANSWER,
      SurveyUIActions.Type.REMOVE_ANSWER,
      SurveyUIActions.Type.LOAD_ANSWERS_DATABASE_SUCCESS,
      SurveyUIActions.Type.LOAD_PREVIEW_SUCCESS),
    switchMap((_ => {
      return [new SurveyUIActions.SetFastForwardPage()];
    }))
  ));

  fastForwardAfterLoadedFromDatabase$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyUIActions.LoadSurveyAnswersFromDatabaseSuccess>(SurveyUIActions.Type.LOAD_ANSWERS_DATABASE_SUCCESS),
    filter(action => action.payload && action.payload.length > 0),
    switchMap(_ => [
      new SurveyUIActions.SetFastForwardPage(),
      new SurveyUIActions.FastForwardPage(),
    ]),
  ));

  submitSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(SurveyUIActions.Type.SUBMIT_SURVEY),
    withLatestFrom(this.store.select(s => s.surveyState),
      this.store.select(x => x.router)),
    switchMap(([_, surveyState, router]) => {
      if (surveyState.previewMode) { return from([new SurveyUIActions.SubmitSurveySuccess()]); }
      const params = this.getRouteParams(router.state.root);
      const surveyKey = params['token'];
      return this.surveyService
        .submit({ sessionId: surveyState.sessionId, answers: surveyState.answers, token: surveyKey })
        .pipe(
          catchError(err => of(err)),
          map((err: any) => {
            return err.ok ? new SurveyUIActions.SubmitSurveySuccess() : new SurveyUIActions.SubmitSurveyFailure();
          }),
        );
    })
  ));

  private evaluateRules(rules: SurveyRule[],
    answers: Answer[],
    changes: (SurveyUIActions.SetQuestionVisibility |
      SurveyUIActions.SetContentVisibility |
      SurveyUIActions.RemoveAnswer |
      SurveyUIActions.SetChapterVisibility)[]) {

    const questionChanges: TargetAction[] = [];
    const removeAnswers: Answer[] = [];

    for (const rule of rules.filter(r => this.canBeEvaluatedOnClient(r.condition))) {

      const conditionValue = this.evaluate(rule.condition, answers);
      const visible = rule.action === 'Hide' && !conditionValue ||
        rule.action === 'Show' && conditionValue;
      for (const target of rule.targets) {
        switch (target.targetType) {
          case 'Question':
            questionChanges.push(<TargetAction>{ id: target.targetId, visible: visible });
            const hasAnswer = answers.find(a => a.questionId === target.targetId);
            if (!visible && hasAnswer) {
              removeAnswers.push(hasAnswer);
            }
            break;
          case 'Content':
            changes.push(new SurveyUIActions.SetContentVisibility({ contentId: target.targetId, visible: visible }));
            break;
          case 'PageGroup':
            changes.push(new SurveyUIActions.SetChapterVisibility({ pageGroupKey: target.targetKey, visible: visible }));
            break;
        }
      }
    }

    if (removeAnswers.length) {
      for (const answer of removeAnswers) {
        changes.push(new SurveyUIActions.RemoveAnswer(answer));
      }
    }

    if (questionChanges.length) {
      changes.push(new SurveyUIActions.SetQuestionVisibility(questionChanges));
    }
  }

  private canBeEvaluatedOnClient(condition: Condition): boolean {
    if (condition == null) {
      return false;
    } else if (condition.logic) {
      return condition.conditions.some(this.canBeEvaluatedOnClient);
    } else {
      const canBeEvaluated = ['Question', 'Const'];
      return canBeEvaluated.includes(condition.operand1.source) &&
        canBeEvaluated.includes(condition.operand2.source);
    }
  }

  private getValue(operand: Operand, answers: Answer[]): string {
    if (operand.source === 'Question') {
      const answer = answers.filter(a => operand.sourceId === a.questionId)
        .map(a => a.value).join(',');
      return answer?.length ? answer : null;
    } else if (operand.source === 'Const') {
      return operand.value;
    }

    return null;
  }

  private evaluate(condition: Condition, answers: Answer[]): boolean {
    if (condition.logic) {
      const children = condition.conditions.map(c => this.evaluate(c, answers));
      if (condition.logic === 'And') {
        return children.every(c => c);
      } else {
        return children.some(c => c);
      }
    } else {
      if (condition.context === 'Backend') { return true; }
      const operand1Value = this.getValue(condition.operand1, answers);
      const operand2Value = this.getValue(condition.operand2, answers);
      const conditionValue = this.evaluateOperands(operand1Value, condition.operator, operand2Value);
      return conditionValue;
    }
  }
  private evaluateOperands(op1: string, operator: string, op2: string): boolean {
    // TODO: Should probably check type for comparison...
    if (operator === 'Eq') {
      if (op1 === null || op2 === null) {
        return (op1 === 'null' || op2 === 'null');
      }
      return op1 === op2;
    } else if (operator === 'Neq') {
      return !this.evaluateOperands(op1, 'Eq', op2);
    } else if (operator === 'In') {
      if (op2 === null) { return false; }
      return op2.split(',').includes(op1 === null ? 'null' : op1);
    } else if (operator === 'Nin') {
      if (op2 === null) { return false; }
      return !op2.split(',').includes(op1 === null ? 'null' : op1);
    } else if (op1 === null || op2 === null) {
      return false;
    } else if (operator === 'Lt') {
      return +op1 < +op2;
    } else if (operator === 'Lte') {
      return +op1 <= +op2;
    } else if (operator === 'Gt') {
      return +op1 > +op2;
    } else if (operator === 'Gte') {
      return +op1 >= +op2;
    }
    return false;
  }

  private getRouteParams(routeData: ActivatedRouteSnapshot): Params {
    let result: Params = { ...routeData.params };
    while (Object.prototype.hasOwnProperty.call(routeData, 'firstChild') && routeData.firstChild) {
      routeData = routeData.firstChild;
      result = { ...result, ...routeData.params };
    }
    return result;
  }

  private getQuestionIds(pageGroups: SurveyPageGroup[]): number[] {
    return pageGroups
      .flatMap(pg => pg.pages)
      .flatMap(p => p.pageItems)
      .filter(pi => pi.question)
      .map(pi => pi.question.questionId);
  }
}
