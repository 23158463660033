import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Company, UserInfo } from 'app/shared/models';
import { AttributeService, CompanyService, SurveyService } from 'app/shared/services';
import * as CompanyContextActions from 'app/state/actions/company-context.actions';
import * as NavTreeAction from 'app/state/actions/navtree.actions';
import * as UserInfoActions from 'app/state/actions/user-info.actions';
import { AppState } from 'app/state/app.state';
import { from } from 'rxjs';
import { combineLatestWith, concatMap, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { companyIdSelect } from '../selectors/company-context.selectors';
import { selectUserInfo } from '../selectors/user-info.selectors';

@Injectable()
export class CompanyContextEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private surveyService: SurveyService,
    private attributeService: AttributeService,
    private store: Store<AppState>) { }

  setCompanyContextSuccess = createEffect(() => this.actions$.pipe(
    ofType<CompanyContextActions.SetCompanyContextSuccess>(CompanyContextActions.Type.SET_COMPANY_CONTEXT_SUCCESS),
    combineLatestWith(this.store.select(s => s.userInfo.displayLanguageId)),
    withLatestFrom(this.store.select(s => s.companyContext.companyLanguages), (this.store.select(s => s.userInfo.languageId))),
    filter(([[_, displayLangId], companyLanguages]) => !!(displayLangId) && companyLanguages?.length > 0),
    switchMap(([[_, displayLangId], companyLanguages, userLanguageId]) => {

      const lang = companyLanguages.find(cl => cl.id === (displayLangId));
      /*  When company context has switched, we check if our current display Language is among the context company languages
      //  If that is the case, we can just reload the navigation
      */
      if (lang) {
        const userLang = companyLanguages.find(cl => cl.id === displayLangId) ?? companyLanguages.find(cl => cl.isDefaultCompanyLanguage);
        return [new NavTreeAction.LoadNavtree(), new UserInfoActions.SetUserContextLanguage(userLang)];
      } else {
        const defaultLang = companyLanguages.find(cl => cl.id === userLanguageId) ?? companyLanguages.find(cl => cl.isDefaultCompanyLanguage);
        return [new UserInfoActions.SetUserContextLanguage(defaultLang)];
      }
    })
  ));

  saveContextToLocalStorage$ = createEffect(() => this.actions$.pipe(
    ofType<CompanyContextActions.SetCompanyContextSuccess>(CompanyContextActions.Type.SET_COMPANY_CONTEXT_SUCCESS),
    withLatestFrom(
      this.store.select(selectUserInfo),
      this.store.select(companyIdSelect)
    ),
    switchMap(([_, userInfo, companyId]) => {
      this.setSavedCompany(companyId, userInfo.userId);
      return from([])
    })
  ));

  setCompanyContext$ = createEffect(() => this.actions$.pipe(
    ofType<CompanyContextActions.SetCompanyContext | CompanyContextActions.SetCompanyContextByShortName>(
      CompanyContextActions.Type.SET_COMPANY_CONTEXT, CompanyContextActions.Type.SET_COMPANY_CONTEXT_BY_SHORT_NAME),
    withLatestFrom(this.store.select(state => state.companyContext), this.store.select(state => state.userInfo)),
    switchMap(([action, companyContext, userInfo]) => {
      if (!action.force && (action.payload === companyContext.id || action.payload === companyContext.shortName)) {
        return from([
          new UserInfoActions.LoadUserInfo(companyContext.id),
          new CompanyContextActions.SetCompanyContextSuccess(companyContext),
          ...action.onSuccess
        ]);
      }
      let companyId: string | number;

      if (!isNaN(+action.payload)) { // case when reloading without companyName in url
        companyId = +action.payload;
        const previousCompanyId = JSON.parse(this.getSavedCompany()) as { companyId: number, userId: number };
        if (!companyContext.id && previousCompanyId?.companyId > 0 && userInfo.userId === previousCompanyId?.userId) {
          companyId = previousCompanyId.companyId;
        }
      }
      return this.companyService.get(companyId || action.payload).pipe(
        concatMap(company => [
          userInfo.userPermission.companyId !== company.id
            ? new UserInfoActions.LoadUserInfo(company.id)
            : new UserInfoActions.LoadUserInfoSuccess(userInfo),
          new CompanyContextActions.SetCompanyContextSuccess(company),
          ...action.onSuccess
        ])
      );
    }
    )
  ));

  setContextByToken$ = createEffect(() => this.actions$.pipe(
    ofType<CompanyContextActions.SetCompanyContextByToken>(CompanyContextActions.Type.SET_COMPANY_CONTEXT_BY_TOKEN),
    switchMap((action) =>
      this.surveyService.getContext(action.payload.token)
    )
  ).pipe(
    switchMap(context => from([
      new UserInfoActions.LoadUserInfoSuccess(context.userContext),
      new CompanyContextActions.SetCompanyContextSuccess({ ...context.companyContext, token: 'abc123' })
    ])))
  );

  useRouteContext$ = createEffect(() => this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
    combineLatestWith(
      this.actions$.pipe(ofType<UserInfoActions.LoadUserInfoSuccess>(UserInfoActions.Type.LOAD_USER_INFO_SUCCESS))
    ),
    withLatestFrom(this.store.select(state => state.companyContext)),
    map(([[routerNavigationAction, userInfo], companyContext]): [Record<string, string>, UserInfo, Company] =>
      ([this.getRouteParams(routerNavigationAction.payload.routerState.root), userInfo.payload, companyContext])),
    filter(([params, userInfo, _]) => userInfo.companyId !== null || 'companyId' in params || 'companyShortName' in params),
    take(1),
    map(([params, userInfo, companyContext]) => {
      let companyId = +{ ...userInfo }.companyId;


      if ('companyShortName' in params) {
        return new CompanyContextActions.SetCompanyContextByShortName(params.companyShortName);
      }

      if ('companyId' in params) {
        companyId = +params.companyId;
      }
      return companyContext?.id === companyId ? new CompanyContextActions.ContextAlreadySet() : new CompanyContextActions.SetCompanyContext(companyId);
    })
  ));

  saveCompanyAttributes$ = createEffect(() => this.actions$.pipe(
    ofType<CompanyContextActions.SaveCompanyAttributes>(CompanyContextActions.Type.SAVE_COMPANY_ATTRIBUTES),
    withLatestFrom(this.store.select(state => state.companyContext.id)),
    switchMap(([action, companyId]) =>
      this.attributeService.updateCompanyMapped(companyId, action.payload)),
    switchMap((res) => from([new CompanyContextActions.SaveCompanyAttributesSuccess(res)]))
  ));

  private getRouteParams(routeData: ActivatedRouteSnapshot) {
    let result = { ...routeData.params };
    while ('firstChild' in routeData && routeData.firstChild) {
      routeData = routeData.firstChild;
      result = { ...result, ...routeData.params };
    }
    return result;
  }

  private setSavedCompany(companyId: number, userId: number) {
    localStorage.setItem('currentCompanyIdAndUserId', JSON.stringify({ companyId: companyId, userId: userId }));
  }

  private getSavedCompany() {
    return localStorage.getItem('currentCompanyIdAndUserId');
  }
}
